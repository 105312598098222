.logoRoelAi {
  position: relative;
  width: 551px;
  height: 167.68px;
}
.logoIcon {
  position: relative;
  width: 1566.96px;
  height: 435.67px;
}
.herologo {
  align-self: stretch;
  height: 800px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.theGeekSpeaks {
  margin: 0 0 0 5vw;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 160px;
  font-weight: 900;
  font-family: inherit;
}
.ifYoureStillContainer {
  margin: 0;
  flex: 1;
  position: relative;
  line-height: 44px;
  color: #666;
}
.ifYoureStillContainerLight {
  color: #FFF;
}
.herocontenttext {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-201xl);
  font-size: var(--font-size-lg);
  font-family: var(--font-red-hat-mono);
}
.hero,
.herocontent {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.herocontent {
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-81xl) 0 0;
  gap: var(--gap-3xs);
}
.hero {
  background-color: var(--color-gray);
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-181xl);
  color: var(--color-white);
  font-family: var(--font-league-spartan);
}
@media screen and (max-width: 420px) {

  .logoRoelAi {
    width: 140px;
  }

  .logoIcon {
    width: 140px;
    height: 200px;
  }
  .herologo {
    height: auto;
    padding-top: var(--padding-81xl);
    box-sizing: border-box;
  }
  .theGeekSpeaks {
    font-size: var(--font-size-71xl);
    line-height: 70px;
    text-align: right;
    margin: 0;
  }
  .ifYoureStillContainer {
    font-size: var(--font-size-sm);
    line-height: 30px;
  }
  .herocontenttext {
    padding: var(--padding-31xl) var(--padding-xl) var(--padding-81xl);
    box-sizing: border-box;
  }
}
