.inTheFastPaced {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 44px;
}
.rowcontentclmn {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}
.imageIcon {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-21xl);
  max-width: 100%;
  overflow: hidden;
  height: 340px;
  flex-shrink: 0;
  object-fit: cover;
}
.rowcontent,
.rowcontentclmn1 {
  align-self: stretch;
  overflow: hidden;
  display: flex;
}
.rowcontentclmn1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}
.rowcontent {
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-41xl) 0;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-red-hat-mono);
}
@media screen and (max-width: 420px) {
  .inTheFastPaced {
    font-size: var(--font-size-sm);
    line-height: 30px;
  }
  .rowcontentclmn,
  .rowcontentclmn1 {
    flex: unset;
    align-self: stretch;
  }
  .rowcontent {
    flex-direction: column;
  }
  .imageIcon {
    border-radius: 0;
  }
}
