@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Mono:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-red-hat-mono: "Red Hat Mono";
  --font-league-spartan: "League Spartan";

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-31xl: 50px;
  --font-size-81xl: 100px;
  --font-size-181xl: 200px;
  --font-size-71xl: 90px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-lightgray: #ccc;
  --color-gray: #111;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-xl: 20px;

  /* Paddings */
  --padding-81xl: 100px;
  --padding-201xl: 220px;
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-21xl: 40px;
  --padding-41xl: 60px;
  --padding-31xl: 50px;

  /* Border radiuses */
  --br-21xl: 40px;
}
