
.vGrid {
  position: fixed;
  top: 0;
  margin: 0 auto;
  height: 100vh;
  width: 90vw;
  box-sizing: border-box;
  border-right: 1px solid #333;
  border-left: 1px solid #333;
  mix-blend-mode: difference;
}
.vGrid:before {
  content: ' ';
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: 100vh;
  background-color: #EEE;
}
.sketchIcon {
  position: relative;
  width: 1440px;
  height: 300px;
  object-fit: cover;
}
.spacer {
  align-self: stretch;
  background-color: var(--color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.footerbodytext,
.footertitle {
  margin: 0;
  align-self: stretch;
  position: relative;
}
.footertitle {
  font-size: inherit;
  line-height: 88px;
  font-weight: 900;
  font-family: inherit;
}
.footerbodytext {
  font-size: var(--font-size-lg);
  line-height: 44px;
  font-family: var(--font-red-hat-mono);
}
.footerwrapper,
.footerwrappercontent {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
}
.footerwrappercontent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  gap: var(--gap-3xs);
}
.footerwrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-xl) 0;
}
.footer,
.home {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.footer {
  align-self: stretch;
  background-color: var(--color-black);
  padding: var(--padding-81xl) var(--padding-201xl);
  text-align: left;
  font-size: 60px;
  color: var(--color-white);
  font-family: var(--font-league-spartan);
}
.home {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
}
@media screen and (max-width: 420px) {
  .footertitle {
    font-size: var(--font-size-31xl);
    line-height: 44px;
  }
  .footerbodytext {
    font-size: var(--font-size-sm);
    line-height: 30px;
  }
  .footer {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
}
